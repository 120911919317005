import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export const Benefits = () => {
  return (
    <section id="benefits" className="section benefits-section">
      <div className="container">
        <div className="section-title">
          <h2>Benefits</h2>
        </div>
        <div className="section-content">
          <p>
            By embracing our generative AI services for education, learners can unlock a range of exciting benefits and transform their learning experience. Some of the expected benefits include:
          </p>
          <ul className="checklist">
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Personalized learning approach tailored to individual needs
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Enhanced understanding and retention of concepts
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Engaging and interactive learning content
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Opportunities for collaboration and peer learning
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              AI-powered language learning tools for improved proficiency
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Real-time feedback and progress tracking
            </li>
            <li>
              <span className="checkmark">
                <FontAwesomeIcon icon={faCheckCircle} />
              </span>
              Flexibility and convenience of learning anytime, anywhere
            </li>
          </ul>
          <p>
            Our generative AI services empower learners to overcome obstacles, achieve their educational goals, and unlock their full potential.
          </p>
        </div>
      </div>
    </section>
  );
};
