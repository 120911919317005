import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const Problem = () => {
  return (
    <div id="problem" className="section" style={{ paddingTop: "50px", paddingBottom: "50px" }}>
      <div className="container">
        <div className="section-title">
          <h2>Problem</h2>
        </div>
        <div className="section-content">
          <p>
            <FontAwesomeIcon icon={faExclamationCircle} /> Many learners struggle with traditional educational approaches that lack personalized support and engagement. The one-size-fits-all model often fails to meet individual learning needs, resulting in frustration and limited progress.
          </p>
          <p>
            <FontAwesomeIcon icon={faExclamationCircle} /> Learners face challenges such as difficulty in understanding complex concepts, inadequate practice opportunities, and limited interaction with peers and instructors. These pain points hinder their ability to fully grasp and retain knowledge, impacting their educational journey.
          </p>
        </div>
      </div>
    </div>
  );
};
