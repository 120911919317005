import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChalkboardTeacher, faFileAlt, faUsers, faLanguage } from "@fortawesome/free-solid-svg-icons";

export const Solution = (props) => {
  return (
    <div id="solution" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Solution</h2>
          <p>
            Our generative AI-powered education platform provides a groundbreaking solution to address the pain points of traditional learning methods. Through the integration of advanced AI technologies, personalized tutoring, and interactive learning content, we revolutionize the way learners engage with educational materials.
          </p>
          <p>
            Our platform leverages AI chatbots and human tutors to deliver personalized tutoring sessions, tailored to each learner's unique needs and preferences. Additionally, our interactive learning content incorporates multimedia elements, such as videos, quizzes, and simulations, fostering active participation and enhancing comprehension and retention of key concepts.
          </p>
          <p>
            Receive personalized tutoring sessions with AI chatbots and human tutors, tailored to your individual learning needs and preferences.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <FontAwesomeIcon icon={getIcon(d.icon)} />
                  <div className="solution-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};

// Helper function to map icon names to corresponding Font Awesome icons
const getIcon = (iconName) => {
  switch (iconName) {
    case "faChalkboardTeacher":
      return faChalkboardTeacher;
    case "faFileAlt":
      return faFileAlt;
    case "faUsers":
      return faUsers;
    case "faLanguage":
      return faLanguage;
    default:
      return null;
  }
};
