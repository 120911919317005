import React from "react";

export const Footer = () => {
  return (
    <div id="footer">
      <div className="container text-center">
        <p>&copy; {new Date().getFullYear()} AI Speak</p>
      </div>
    </div>
  );
};
